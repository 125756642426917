.alert-details {
  padding: 120px 0 20px 0;
  background: #FFF1F1;
} 
.alert-details h6 {
  color: #000;
  margin: 0 0 30px;
  font-family: Sharp Grotesk DB Cyr Medium\ 22;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.alert-details h1 {
  color: #000;
  margin: 0 0 50px;
  font-family: Sharp Grotesk DB Cyr Medium\ 22;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 66px;
}
.alert-details p {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 20px;
}