/* Testimonial CSS */
.slick-slider {
  position: relative;
}
.slide:focus {
  outline: none !important;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-initialized .slick-slide {
  display: block;
}
.testimonials-slides {
	display: flex !important;
}
.clients-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
/* .clients-review {
	width: calc(100% - 400px);
} */
.clients-review {
	display: flex;
	align-items: flex-start;
}
.clients-review .review-invert {
	margin: 0 10px 0 0;
}
.logo-description div {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: 0.01em;
	color: #000000;
}
.logo-description div img {
	margin: -20px 0 0 0;
}
.logo-description p {
	font-style: italic;
	font-weight: normal;
	font-size: 18px;
	line-height: 36px;
	letter-spacing: 0.01em;
	color: #828E99;
	margin: 0 0 30px;
}
.logo-description figure {
	margin: 30px 0 0 0;
}
.testimonials-logs {
	margin: 80px 0 120px 0;
}
.testimonials-logs .slick-arrow {
	background: none;
  border: none;
  position: absolute;
  right: 0;
  bottom: 70px;
  z-index: 9;
  width: 27px;
  hyphens: 15px;
}
.testimonials-logs .slick-prev {
	background: url('../images/arrow_left_gray.svg') no-repeat scroll 0 0 / 27px;
	color: transparent;
	right: 67px;
}
.testimonials-logs .slick-next {
	background: url('../images/arrow_right_gray.svg') no-repeat scroll 0 0 / 27px;
	color: transparent;
	right: 0;
}
/*  */