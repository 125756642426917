.main-body-bg {
  background: url(../../assets/images/common_bg.png) no-repeat scroll left -70px top 60px;
}
.banner-text {
	padding: 180px 0 100px 0;
}
.tab-list-view ul {
	display: flex;
	align-items: center;
	margin: 0px 0 80px;
}
.tab-list-view ul li+li {
	margin: 0 0 0 30px;
}
.tab-list-view ul.plan-tabs li {
	font-family: Arial;
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 30px;
	color: #425466;
	border-bottom: 3px solid transparent;
	cursor: pointer;
}
.tab-list-view ul li a:hover, .tab-active-border {
	color: #0065FF !important;
	border-bottom: 3px solid #0065FF !important;
}
.tab-list-details {
	padding: 0 80px;
}
.custom-table {
	width: 100%;
}
.custom-table tr th {
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding: 18px 10px;
}
.custom-table tr th:nth-child(1) {
	width: 30%;
}
.custom-table tr td {
	font-style: normal;
	font-size: 20px;
	line-height: 24px;
	color: #828E99;
	padding: 18px 10px;
	text-align: center;
}
.custom-table tr td:nth-child(1) {
	text-align: left;
}
.custom-table tr td span {
	color: #425466;
}
.custom-table tr td .get-start {
	margin: 0;
}
.availability-link {
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: #828E99;
	margin: 80px 0 100px;
}
.availability-link a {
	color: #0065FF;
}
.availability-link ul {
	display: flex;
	align-items: center;
	margin: 0 -15px 0px;
	flex-wrap: wrap;
	justify-content: space-between;
}
.availability-link ul li {
	margin: 0 15px;
}
.faq-list {
	background: #F6F9FC;
	padding: 100px 0;
}
.accordion-container {
	margin: 30px 0 0 0;
}
.set {
  position: relative;
}
.set > a {
  display: block;
  transition:all 0.2s linear;
  font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
	padding: 20px 40px 20px 20px;
	position: relative;
	border: 1px solid #fff;
	border-top: 1px solid #E1E5E9;
	border-bottom: none;
}
.set > a .fa-plus:before {
	position: absolute;
	content: '';
	right: 20px;
	top: 50%;
  margin: -10px 0 0 0;
	width: 20px;
	height: 20px;
	background: url('../../assets/images/plus.png') no-repeat scroll 0 0 / 20px;
}
.set > a .fa-minus:before {
	position: absolute;
	content: '';
	right: 20px;
	top: 50%;
  margin: -1px 0 0 0;
	width: 20px;
	height: 2px;
	background: url('../../assets/images/minus.png') no-repeat scroll 0 0 / 20px;
}
.set > a.active-accordian {
	border: 1px solid #E1E5E9;
	border-bottom: none;
}
.content-accordian {
  display:none;
	border: 1px solid #E1E5E9;
	border-top: none;
}
.content-accordian p {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: #828E99;
  font-weight: normal;
  padding: 0px 20px 20px 20px;
}
.table-bg-1 {
	background: #0065ff0f;
	border-radius: 10px;
}
.table-bg-2 {
	background: #f2daed5c;
	border-radius: 10px;
}
.accordion-container-table .set > a .fa-plus:before {
	position: absolute;
	content: '';
  right: 10px;
  top: 50%;
  margin: -4px 0 0 0;
	width: 20px;
	height: 20px;
	background: url(../../assets/images/down_arrow_big.png) no-repeat scroll 0 0 / 14px;
}
.accordion-container-table .set > a .fa-minus:before {
	position: absolute;
	content: '';
  right: 10px;
  top: 50%;
  margin: -4px 0 0 0;
	width: 20px;
	height: 20px;
	background: url(../../assets/images/down_up_big.png) no-repeat scroll 0 0 / 14px;
}
.accordion-container-table table tr th, .accordion-container-table table tr td {
	padding: 8px 12px;
	font-size: 16px;
	background: #ffffff;
}
.accordion-container-table table tr td img {
	width: 15px;
}
.accordion-container-table table tr td:last-child {
	width: 250px;
	text-align: right;
}
.accordion-container-table .content-accordian {
	border: none !important;
}
.choose-bg01 a, .choose-bg01 table tr th, .choose-bg01 table tr td {
	background: #f5f8f9 !important;
}
.custom-table-sp {
	display: none;
}

.custom-table tr td .primaryBtn {
	margin: 0;
	background: #0065FF !important;
}
.custom-table tr td .primaryBtn:hover {
	margin: 0;
	background: #ffffff !important;
}
@media screen and (max-width: 768px){
	.accordion-container-table .set > a {
		background: #ffffff;
		font-size: 18px;
		padding: 8px 12px;
		border: none;
		border-bottom: 1px solid #eaeaeacc;
	}
	.custom-table-pc {
		display: none;
	}
	.custom-table-sp {
		display: block;
	}

}