.verification-tab-list-view {
	padding: 110px 0 0px 0;
}
.verification-tab-list-view ul {
	margin: 0 0 30px;
}
.verification-tab-list-details {
	padding: 0;
}
.custom-insights {
	margin: 50px 0 0 0 !important;
}
.custom-insights ul {
	display: block;
}
.custom-insights ul li {
	display: flex;
	align-items: center;
	margin: 0 0 36px;
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: #596980;
}
.custom-insights ul li+li {
	margin: 0 0 30px;
}
.custom-insights ul li img {
	margin: -1px 15px 0 0;
}
.custom-insights .get-start {
	margin: 0;
}
.tab-list-view ul.prods-tabs li {
	font-family: Arial;
	display: block;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	color: #425466;
	border-bottom: 3px solid transparent;
	cursor: pointer;
}
.banner-padding-products{
  padding-top: 90px;
}