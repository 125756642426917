.hubspot-form-container {
	margin: 30px 0 100px;
	position: relative;
	margin-left: 10px;
}

.hubspot-form-container> :first-child {
	max-width: 790px;
}

.hubspot-form-container input[type="text"],
.hubspot-form-container input[type="email"],
.hubspot-form-container input[type="tel"],
.hubspot-form-container textarea {
	display: block;
	border: 2px solid #ECECEC;
	box-sizing: border-box;
	border-radius: 4px;
	height: 58px;
	line-height: 58px;
	font-size: 14px;
	color: #425466;
	padding: 0 12px;
	width: 100%;
	background: #fff;
	margin-top: 16px;
}

.hubspot-form-container textarea {
	height: 120px;
	padding: 20px;
	line-height: 17px;
	outline: none;
}

.hubspot-form-container .hs-form-field label span {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #999999;
	left: 27px;
	top: -9px;
	background: #fff;
	padding: 0 4px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.hubspot-form-container .hs-form-field label span[class="hs-form-required"] {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.hubspot-form-container .hs-form-field input[type="text"]:focus+label,
.hubspot-form-container .hs-form-field input[type="email"]:focus+label,
.hubspot-form-container .hs-form-field input[type="tel"]:focus+label,
.hubspot-form-container .hs-form-field textarea:focus+label,
.hubspot-form-container .hs-form-field input[type="text"]:valid+label,
.hubspot-form-container .hs-form-field input[type="email"]:valid+label,
.hubspot-form-container .hs-form-field input[type="tel"]:valid+label,
.hubspot-form-container .hs-form-field textarea:focus+label {
	top: -24px;
	font-size: 12px;
}

.hs-custom-style>div input:not([type=image]):not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]):not([type=file]),
.hs-custom-style fieldset input:not([type=image]):not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]):not([type=file]) {
	box-sizing: border-box;
	padding: 0 15px;
	min-height: 27px;
	margin-top: -30px;
	margin-left: -15px;
}

.hs-custom-style>div>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]),
.hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]) {
	width: 100%;
	max-width: 100%;
	margin-left: -15px;
}

.hubspot-form-container .hs-form-field textarea {
	margin-top: -30px;
	margin-bottom: 10px;
	padding-top: 20px;
}

.hubspot-form-container .hs-form-field legend[class="hs-field-desc"] {
	display: none !important;
}

.hubspot-form-container fieldset[class="form-columns-1"] div[class="legal-consent-container"] div[class="hs-richtext"] p {
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	line-height: 17px;
	color: #828E99;
	font-family: 'Archivo', sans-serif !important;
}

.hubspot-form-container .hs-button {
	width: 136px;
	height: 48px;
	background: #0065FF;
	border-radius: 6px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 27px;
	color: #fff;
	transition: all;
	box-sizing: border-box;
	border: 2px solid #0065FF;
	margin-top: 60px;
	margin-left: -15px;
}

.hs-custom-style .hs-input,
.hs-custom-style fieldset div {
	max-width: 100%;
	padding-top: 15px;
}

.hubspot-form-container fieldset[class="form-columns-1"] div[class="legal-consent-container"] div[class="hs-richtext"] p {
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	line-height: 17px;
	color: #828E99;
	font-family: 'Archivo', sans-serif !important;
	margin-top: 72px;
	position: absolute;
	margin-left: -15px;
}

.hubspot-form-container div[class="hs_recaptcha"] {
	margin-top: -94px;
	margin-bottom: 15px;
	margin-left: -15px;
}

.hs_recaptcha {
	margin-left: -15px;
}

.hs_recaptcha div {
	border-radius: 5px;
}

.hs-error-msg {
	color: red;
}

div.submitted-message p {
	font-family: 'Archivo', sans-serif;
	font-size: 14px !important;
	color: #828E99;
}

.error-message {
	display: flex;
	flex-direction: row;
	justify-content: left;
	padding-left: 15px;
	padding-top: 30px;
	padding-bottom: 220px;
	font-family: 'Archivo', sans-serif;
	font-size: 14px !important;
	color: #828E99;
}

.hs-error-msg {
	margin-bottom: 0px;
	padding-bottom: 0px;
	position: absolute;
	float: left;
}

.verification-tab-list-view ul {
	margin: 0 0 13px;
	padding-left: 0px;
}

.col-md-12 {
	padding: 0
}

label[class='hs-main-font-element'] {
	margin-top: 40px;
	padding-left: 0px;
	margin-left: -15px;
}

.kreditz-email {
	color: "#0065FF";
}

@media screen and (max-width: 480px) {
	.hubspot-form-container {
		margin-left: 20px;
		margin-top: 0px;
	}
}