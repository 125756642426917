
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.eot"); /* IE9*/
  src: url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.woff2") format("woff2"), /* chrome firefox */
  url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.woff") format("woff"), /* chrome firefox */
  url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("./assets/fonts/SEProDisplay/0b51833ff62e4af8acb5fd3e2bf59e97.svg#SF Pro Display") format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  src: url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.eot"); /* IE9*/
  src: url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.woff2") format("woff2"), /* chrome firefox */
  url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.woff") format("woff"), /* chrome firefox */
  url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("./assets/fonts/sharp22/0b6986b36b7d81188f996e58f2ec0643.svg#Sharp Grotesk DB Cyr Medium 22") format("svg"); /* iOS 4.1- */
}

*{
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
  font-family: "SF Pro Display" !important;
  color: #425466;
  line-height: 1.6;
  font-size: 14px;
  overflow-x: hidden;
}
ul li {
  list-style-type: none;
}
html,body,p,ul,li,ol,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
a, a:hover, a:focus {
  text-decoration: none;
  transition: all 0.3s;
}
input:focus, select:focus, a:focus, button:focus, a:hover {
  outline: none !important;
  box-shadow: none !important;
}
/*==========================*/
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* Common Css */
#Wrapper {
  min-width: 320px;
}
#header {
  position: fixed;
  background: #fff;
  left: 0;
  top: -1;
  width: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #F0F0F0;
}
.inner-header {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* left-side CSS */
.logo {
}
/* center-side-navigation Dropdown Menu CSS */
.center-side-navigation .ruby-menu{
  margin-bottom: 0em !important;
}
.ruby-menu {
  position: relative;
  display: flex;
}
.ruby-menu>li.ruby-menu-mega>div {
  position: fixed;
  width: 100%;
  height: calc(100vh - 80px);
  left: 0;
  background: gray;
  overflow: hidden;
  background: rgba(0,0,0,0.5);
  transition: all 0.5s;
}
.ruby-menu>li:hover>ul,
.ruby-menu>li>ul>li:hover>ul,
.ruby-menu>li>ul>li>ul>li:hover>ul {
  visibility: visible;
  opacity: 1;
}
.ruby-menu>li+li {
  margin: 0 0 0 40px;
}
.ruby-menu>li .open-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #425466;
  display: flex;
  align-items: center;
  padding: 26px 0;
  transition: all 0.3s;
  background: #fff;
}
.ruby-menu li .open-link span {
  display: inline-block;
  transition: all 0.3s;
  margin: 0 0 0 14px;
}
.ruby-menu>li .open-link:hover span {
  margin: 0 0 0 14px;
}
.ruby-menu>li.ruby-menu-mega>.full-dropdown {
  display: none;
  transition: all 0.5s;
}
.ruby-menu>li.ruby-menu-mega:hover>.full-dropdown {
  /*visibility: visible;
  opacity: 1;*/
  transition: all 0.5s;
}
.show-full-dropdown {
  visibility: visible !important;
  opacity: 1 !important;
}
.ruby-menu>li.ruby-menu-mega>.full-dropdown .inner-full-dropdown {
  width: 100%;
  background: #fff;
}
.ruby-menu>li.ruby-menu-mega>.full-dropdown .inner-full-dropdown .menu-drop-down {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  /*height: 400px;
  overflow-y: auto;*/
}
.label-menu {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #828e99;
  margin: 0 0 15px;
  font-weight: normal;
}
.label-menu strong {
  display: block;
  color: #000;
}
/* right-side CSS */
.right-side, .right-side-buttons {
  display: flex;
  align-items: center;
}
.login {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: center;
}
.login img {
  margin: 0 10px 0 0;
}
.get-start {
  width: 136px;
  height: 48px;
  background: #0065FF;
  border-radius: 6px;
  line-height: 44px;
  margin: 0 0 0 20px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #fff;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 2px solid #0065FF;
}
.get-start:hover {
  border: 2px solid #0065FF;
  background: #fff;
  color: #0065FF;
}
.white-btn{
  width: 136px;
  height: 48px;
  background: white;
  border-radius: 6px;
  line-height: 44px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #0065FF;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 2px solid #0065FF;
}
.white-btn:hover{
  border: 2px solid #0065FF;
  background: #0065FF;
  color:white;
}
.contact-us-btn {
  width: 136px;
  display: block;
  margin: 25px 0 0 0;
  height: 48px;
  background: #0065FF;
  box-sizing: border-box;
  border: 2px solid #0065FF;
  border-radius: 6px;
  line-height: 46px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  transition: all 0.3s;
}
.contact-us-btn:hover{
  border: 2px solid #0065FF;
  background: #fff;
  color: #0065FF;
}
/* Footer CSS */
#footer {
  border-top: 1px solid #F0F0F0;
}
.inner-footer {
  padding: 40px 0 20px;
}
.foot-links ul li h5 {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 12px;
  text-transform: uppercase;
  color: #828E99;
}
.foot-links ul li , .foot-links ul li a {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 20px;
  color: #000000;
  transition: all 0.3s;
}
.foot-links ul li {
  display: block;
  margin: 0;
}
.foot-links ul li a:hover {
  color: #0065FF;
}
.social-freditz-country {
  background: #F6F9FC;
  padding: 15px 0px;
}
.social-copyrights {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyright {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #828E99;
}
.social-links {
  margin: 0 0 0 -80px;
}
.social-links a {
  display: inline-block;
  margin: 0 5px;
}
.select-language .dropdown-toggle {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  background: none;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}
.select-language .dropdown-toggle img {
  margin: 0 5px;
}
.select-language .dropdown-toggle .caret {
  color: transparent;
  background: none;
  border: 0;
  width: 10px;
  height: 6px;
  margin: -9px 0 0 7px;
  vertical-align: top;
}
.select-language .dropdown-toggle .caret img {
  margin: 0;
}
.select-language .dropdown .dropdown-menu {
  top: auto;
  bottom: 25px !important;
  min-width: 100%;
  min-height: 0;
  overflow-y: auto;
  max-height: 117px;
  margin: 0;
  padding: 0;
}
.select-language .dropdown .dropdown-menu li a {
  padding: 3px 6px 5px;
}
/* Common Main BG */
.main-body-bg {
  background: url(../src/assets/images/common_bg.png) no-repeat scroll left -70px top 60px;
}
/* Common headings */
/* H1 */
.h1-basic01 {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 66px;
  color: #000;
}
/* H2 */
.h2-basic01 {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 65px;
  color: #000000;
}
.h2-basic02 {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  margin: 0 0 20px;
  color: #425466;
}
/* H3 */
.h3-basic01 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #000;
  margin: 0 0 6px;
}
.h3-basic02 {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  color: #425466;
  margin: 30px 0 14px 0;
}
/* H4 */
.h4-basic01 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  color: #828E99;
  margin: 0 0 6px;
}
/* H5 */
.h5-basic01 {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  margin: 0 0 26px;
}
/* H6 */
.h6-basic01 {
  margin: 10px 0 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  color: #425466;
}
.h6-basic02 {
  font-style: normal;
  font-weight: normal;
  font-size: 18.6591px;
  line-height: 22px;
  color: #000000;
}
.h6-basic03 {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #425466;}
/* Common Para */
.width-1200 {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}
.menu-para {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}
/* Common Margin/Padding */
.m-0 {
  margin: 0;
}
.pt-45 {
  padding-top: 45px;
}
span.down-arrow{
  transform: rotate(0deg);
}
span.up-arrow{
  transform: rotate(180deg);  
}
