.flagstrap {
  display: inline-block;
  position: relative;
  width: 100%;
}

#basic ul {
  width: 100%;
}

.btn.btn-default.btn-md.dropdown-toggle span:nth-child(1) {
  margin-right: 15px;
}
.btn.btn-default.btn-md.dropdown-toggle .caret {
  position: absolute;
}

.flagstrap-icon {
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url('../src/assets/images/flag.png') no-repeat
}

.flagstrap-icon.flagstrap-ad {
  background-position: -16px 0
}

.flagstrap-icon.flagstrap-ae {
  background-position: -32px 0
}

.flagstrap-icon.flagstrap-af {
  background-position: -48px 0
}

.flagstrap-icon.flagstrap-ag {
  background-position: -64px 0
}

.flagstrap-icon.flagstrap-ai {
  background-position: -80px 0
}

.flagstrap-icon.flagstrap-al {
  background-position: -96px 0
}

.flagstrap-icon.flagstrap-am {
  background-position: -112px 0
}

.flagstrap-icon.flagstrap-an {
  background-position: -128px 0
}

.flagstrap-icon.flagstrap-ao {
  background-position: -144px 0
}

.flagstrap-icon.flagstrap-ar {
  background-position: -160px 0
}

.flagstrap-icon.flagstrap-as {
  background-position: -176px 0
}

.flagstrap-icon.flagstrap-at {
  background-position: -192px 0
}

.flagstrap-icon.flagstrap-au {
  background-position: -208px 0
}

.flagstrap-icon.flagstrap-aw {
  background-position: -224px 0
}

.flagstrap-icon.flagstrap-az {
  background-position: -240px 0
}

.flagstrap-icon.flagstrap-ba {
  background-position: 0 -11px
}

.flagstrap-icon.flagstrap-bb {
  background-position: -16px -11px
}

.flagstrap-icon.flagstrap-bd {
  background-position: -32px -11px
}

.flagstrap-icon.flagstrap-be {
  background-position: -48px -11px
}

.flagstrap-icon.flagstrap-bf {
  background-position: -64px -11px
}

.flagstrap-icon.flagstrap-bg {
  background-position: -80px -11px
}

.flagstrap-icon.flagstrap-bh {
  background-position: -96px -11px
}

.flagstrap-icon.flagstrap-bi {
  background-position: -112px -11px
}

.flagstrap-icon.flagstrap-bj {
  background-position: -128px -11px
}

.flagstrap-icon.flagstrap-bm {
  background-position: -144px -11px
}

.flagstrap-icon.flagstrap-bn {
  background-position: -160px -11px
}

.flagstrap-icon.flagstrap-bo {
  background-position: -176px -11px
}

.flagstrap-icon.flagstrap-br {
  background-position: -192px -11px
}

.flagstrap-icon.flagstrap-bs {
  background-position: -208px -11px
}

.flagstrap-icon.flagstrap-bt {
  background-position: -224px -11px
}

.flagstrap-icon.flagstrap-bv {
  background-position: -240px -11px
}

.flagstrap-icon.flagstrap-bw {
  background-position: 0 -22px
}

.flagstrap-icon.flagstrap-by {
  background-position: -16px -22px
}

.flagstrap-icon.flagstrap-bz {
  background-position: -32px -22px
}

.flagstrap-icon.flagstrap-ca {
  background-position: -48px -22px
}

.flagstrap-icon.flagstrap-catalonia {
  background-position: -64px -22px
}

.flagstrap-icon.flagstrap-cd {
  background-position: -80px -22px
}

.flagstrap-icon.flagstrap-cf {
  background-position: -96px -22px
}

.flagstrap-icon.flagstrap-cg {
  background-position: -112px -22px
}

.flagstrap-icon.flagstrap-ch {
  background-position: -128px -22px
}

.flagstrap-icon.flagstrap-ci {
  background-position: -144px -22px
}

.flagstrap-icon.flagstrap-ck {
  background-position: -160px -22px
}

.flagstrap-icon.flagstrap-cl {
  background-position: -176px -22px
}

.flagstrap-icon.flagstrap-cm {
  background-position: -192px -22px
}

.flagstrap-icon.flagstrap-cn {
  background-position: -208px -22px
}

.flagstrap-icon.flagstrap-co {
  background-position: -224px -22px
}

.flagstrap-icon.flagstrap-cr {
  background-position: -240px -22px
}

.flagstrap-icon.flagstrap-cu {
  background-position: 0 -33px
}

.flagstrap-icon.flagstrap-cv {
  background-position: -16px -33px
}

.flagstrap-icon.flagstrap-cw {
  background-position: -32px -33px
}

.flagstrap-icon.flagstrap-cy {
  background-position: -48px -33px
}

.flagstrap-icon.flagstrap-cz {
  background-position: -64px -33px
}

.flagstrap-icon.flagstrap-de {
  background-position: -80px -33px
}

.flagstrap-icon.flagstrap-dj {
  background-position: -96px -33px
}

.flagstrap-icon.flagstrap-dk {
  background-position: -112px -33px
}

.flagstrap-icon.flagstrap-dm {
  background-position: -128px -33px
}

.flagstrap-icon.flagstrap-do {
  background-position: -144px -33px
}

.flagstrap-icon.flagstrap-dz {
  background-position: -160px -33px
}

.flagstrap-icon.flagstrap-ec {
  background-position: -176px -33px
}

.flagstrap-icon.flagstrap-ee {
  background-position: -192px -33px
}

.flagstrap-icon.flagstrap-eg {
  background-position: -208px -33px
}

.flagstrap-icon.flagstrap-eh {
  background-position: -224px -33px
}

.flagstrap-icon.flagstrap-england {
  background-position: -240px -33px
}

.flagstrap-icon.flagstrap-er {
  background-position: 0 -44px
}

.flagstrap-icon.flagstrap-es {
  background-position: -16px -44px
}

.flagstrap-icon.flagstrap-et {
  background-position: -32px -44px
}

.flagstrap-icon.flagstrap-eu {
  background-position: -48px -44px
}

.flagstrap-icon.flagstrap-fi {
  background-position: -64px -44px
}

.flagstrap-icon.flagstrap-fj {
  background-position: -80px -44px
}

.flagstrap-icon.flagstrap-fk {
  background-position: -96px -44px
}

.flagstrap-icon.flagstrap-fm {
  background-position: -112px -44px
}

.flagstrap-icon.flagstrap-fo {
  background-position: -128px -44px
}

.flagstrap-icon.flagstrap-fr {
  background-position: -144px -44px
}

.flagstrap-icon.flagstrap-ga {
  background-position: -160px -44px
}

.flagstrap-icon.flagstrap-gb {
  background-position: -176px -44px
}

.flagstrap-icon.flagstrap-gd {
  background-position: -192px -44px
}

.flagstrap-icon.flagstrap-ge {
  background-position: -208px -44px
}

.flagstrap-icon.flagstrap-gf {
  background-position: -224px -44px
}

.flagstrap-icon.flagstrap-gg {
  background-position: -240px -44px
}

.flagstrap-icon.flagstrap-gh {
  background-position: 0 -55px
}

.flagstrap-icon.flagstrap-gi {
  background-position: -16px -55px
}

.flagstrap-icon.flagstrap-gl {
  background-position: -32px -55px
}

.flagstrap-icon.flagstrap-gm {
  background-position: -48px -55px
}

.flagstrap-icon.flagstrap-gn {
  background-position: -64px -55px
}

.flagstrap-icon.flagstrap-gp {
  background-position: -80px -55px
}

.flagstrap-icon.flagstrap-gq {
  background-position: -96px -55px
}

.flagstrap-icon.flagstrap-gr {
  background-position: -112px -55px
}

.flagstrap-icon.flagstrap-gs {
  background-position: -128px -55px
}

.flagstrap-icon.flagstrap-gt {
  background-position: -144px -55px
}

.flagstrap-icon.flagstrap-gu {
  background-position: -160px -55px
}

.flagstrap-icon.flagstrap-gw {
  background-position: -176px -55px
}

.flagstrap-icon.flagstrap-gy {
  background-position: -192px -55px
}

.flagstrap-icon.flagstrap-hk {
  background-position: -208px -55px
}

.flagstrap-icon.flagstrap-hm {
  background-position: -224px -55px
}

.flagstrap-icon.flagstrap-hn {
  background-position: -240px -55px
}

.flagstrap-icon.flagstrap-hr {
  background-position: 0 -66px
}

.flagstrap-icon.flagstrap-ht {
  background-position: -16px -66px
}

.flagstrap-icon.flagstrap-hu {
  background-position: -32px -66px
}

.flagstrap-icon.flagstrap-ic {
  background-position: -48px -66px
}

.flagstrap-icon.flagstrap-id {
  background-position: -64px -66px
}

.flagstrap-icon.flagstrap-ie {
  background-position: -80px -66px
}

.flagstrap-icon.flagstrap-il {
  background-position: -96px -66px
}

.flagstrap-icon.flagstrap-im {
  background-position: -112px -66px
}

.flagstrap-icon.flagstrap-in {
  background-position: -128px -66px
}

.flagstrap-icon.flagstrap-io {
  background-position: -144px -66px
}

.flagstrap-icon.flagstrap-iq {
  background-position: -160px -66px
}

.flagstrap-icon.flagstrap-ir {
  background-position: -176px -66px
}

.flagstrap-icon.flagstrap-is {
  background-position: -192px -66px
}

.flagstrap-icon.flagstrap-it {
  background-position: -208px -66px
}

.flagstrap-icon.flagstrap-je {
  background-position: -224px -66px
}

.flagstrap-icon.flagstrap-jm {
  background-position: -240px -66px
}

.flagstrap-icon.flagstrap-jo {
  background-position: 0 -77px
}

.flagstrap-icon.flagstrap-jp {
  background-position: -16px -77px
}

.flagstrap-icon.flagstrap-ke {
  background-position: -32px -77px
}

.flagstrap-icon.flagstrap-kg {
  background-position: -48px -77px
}

.flagstrap-icon.flagstrap-kh {
  background-position: -64px -77px
}

.flagstrap-icon.flagstrap-ki {
  background-position: -80px -77px
}

.flagstrap-icon.flagstrap-km {
  background-position: -96px -77px
}

.flagstrap-icon.flagstrap-kn {
  background-position: -112px -77px
}

.flagstrap-icon.flagstrap-kp {
  background-position: -128px -77px
}

.flagstrap-icon.flagstrap-kr {
  background-position: -144px -77px
}

.flagstrap-icon.flagstrap-kurdistan {
  background-position: -160px -77px
}

.flagstrap-icon.flagstrap-kw {
  background-position: -176px -77px
}

.flagstrap-icon.flagstrap-ky {
  background-position: -192px -77px
}

.flagstrap-icon.flagstrap-kz {
  background-position: -208px -77px
}

.flagstrap-icon.flagstrap-la {
  background-position: -224px -77px
}

.flagstrap-icon.flagstrap-lb {
  background-position: -240px -77px
}

.flagstrap-icon.flagstrap-lc {
  background-position: 0 -88px
}

.flagstrap-icon.flagstrap-li {
  background-position: -16px -88px
}

.flagstrap-icon.flagstrap-lk {
  background-position: -32px -88px
}

.flagstrap-icon.flagstrap-lr {
  background-position: -48px -88px
}

.flagstrap-icon.flagstrap-ls {
  background-position: -64px -88px
}

.flagstrap-icon.flagstrap-lt {
  background-position: -80px -88px
}

.flagstrap-icon.flagstrap-lu {
  background-position: -96px -88px
}

.flagstrap-icon.flagstrap-lv {
  background-position: -112px -88px
}

.flagstrap-icon.flagstrap-ly {
  background-position: -128px -88px
}

.flagstrap-icon.flagstrap-ma {
  background-position: -144px -88px
}

.flagstrap-icon.flagstrap-mc {
  background-position: -160px -88px
}

.flagstrap-icon.flagstrap-md {
  background-position: -176px -88px
}

.flagstrap-icon.flagstrap-me {
  background-position: -192px -88px
}

.flagstrap-icon.flagstrap-mg {
  background-position: -208px -88px
}

.flagstrap-icon.flagstrap-mh {
  background-position: -224px -88px
}

.flagstrap-icon.flagstrap-mk {
  background-position: -240px -88px
}

.flagstrap-icon.flagstrap-ml {
  background-position: 0 -99px
}

.flagstrap-icon.flagstrap-mm {
  background-position: -16px -99px
}

.flagstrap-icon.flagstrap-mn {
  background-position: -32px -99px
}

.flagstrap-icon.flagstrap-mo {
  background-position: -48px -99px
}

.flagstrap-icon.flagstrap-mp {
  background-position: -64px -99px
}

.flagstrap-icon.flagstrap-mq {
  background-position: -80px -99px
}

.flagstrap-icon.flagstrap-mr {
  background-position: -96px -99px
}

.flagstrap-icon.flagstrap-ms {
  background-position: -112px -99px
}

.flagstrap-icon.flagstrap-mt {
  background-position: -128px -99px
}

.flagstrap-icon.flagstrap-mu {
  background-position: -144px -99px
}

.flagstrap-icon.flagstrap-mv {
  background-position: -160px -99px
}

.flagstrap-icon.flagstrap-mw {
  background-position: -176px -99px
}

.flagstrap-icon.flagstrap-mx {
  background-position: -192px -99px
}

.flagstrap-icon.flagstrap-my {
  background-position: -208px -99px
}

.flagstrap-icon.flagstrap-mz {
  background-position: -224px -99px
}

.flagstrap-icon.flagstrap-na {
  background-position: -240px -99px
}

.flagstrap-icon.flagstrap-nc {
  background-position: 0 -110px
}

.flagstrap-icon.flagstrap-ne {
  background-position: -16px -110px
}

.flagstrap-icon.flagstrap-nf {
  background-position: -32px -110px
}

.flagstrap-icon.flagstrap-ng {
  background-position: -48px -110px
}

.flagstrap-icon.flagstrap-ni {
  background-position: -64px -110px
}

.flagstrap-icon.flagstrap-nl {
  background-position: -80px -110px
}

.flagstrap-icon.flagstrap-no {
  background-position: -96px -110px
}

.flagstrap-icon.flagstrap-np {
  background-position: -112px -110px
}

.flagstrap-icon.flagstrap-nr {
  background-position: -128px -110px
}

.flagstrap-icon.flagstrap-nu {
  background-position: -144px -110px
}

.flagstrap-icon.flagstrap-nz {
  background-position: -160px -110px
}

.flagstrap-icon.flagstrap-om {
  background-position: -176px -110px
}

.flagstrap-icon.flagstrap-pa {
  background-position: -192px -110px
}

.flagstrap-icon.flagstrap-pe {
  background-position: -208px -110px
}

.flagstrap-icon.flagstrap-pf {
  background-position: -224px -110px
}

.flagstrap-icon.flagstrap-pg {
  background-position: -240px -110px
}

.flagstrap-icon.flagstrap-ph {
  background-position: 0 -121px
}

.flagstrap-icon.flagstrap-pk {
  background-position: -16px -121px
}

.flagstrap-icon.flagstrap-pl {
  background-position: -32px -121px
}

.flagstrap-icon.flagstrap-pm {
  background-position: -48px -121px
}

.flagstrap-icon.flagstrap-pn {
  background-position: -64px -121px
}

.flagstrap-icon.flagstrap-pr {
  background-position: -80px -121px
}

.flagstrap-icon.flagstrap-ps {
  background-position: -96px -121px
}

.flagstrap-icon.flagstrap-pt {
  background-position: -112px -121px
}

.flagstrap-icon.flagstrap-pw {
  background-position: -128px -121px
}

.flagstrap-icon.flagstrap-py {
  background-position: -144px -121px
}

.flagstrap-icon.flagstrap-qa {
  background-position: -160px -121px
}

.flagstrap-icon.flagstrap-re {
  background-position: -176px -121px
}

.flagstrap-icon.flagstrap-ro {
  background-position: -192px -121px
}

.flagstrap-icon.flagstrap-rs {
  background-position: -208px -121px
}

.flagstrap-icon.flagstrap-ru {
  background-position: -224px -121px
}

.flagstrap-icon.flagstrap-rw {
  background-position: -240px -121px
}

.flagstrap-icon.flagstrap-sa {
  background-position: 0 -132px
}

.flagstrap-icon.flagstrap-sb {
  background-position: -16px -132px
}

.flagstrap-icon.flagstrap-sc {
  background-position: -32px -132px
}

.flagstrap-icon.flagstrap-scotland {
  background-position: -48px -132px
}

.flagstrap-icon.flagstrap-sd {
  background-position: -64px -132px
}

.flagstrap-icon.flagstrap-se {
  background-position: -80px -132px
}

.flagstrap-icon.flagstrap-sg {
  background-position: -96px -132px
}

.flagstrap-icon.flagstrap-sh {
  background-position: -112px -132px
}

.flagstrap-icon.flagstrap-si {
  background-position: -128px -132px
}

.flagstrap-icon.flagstrap-sk {
  background-position: -144px -132px
}

.flagstrap-icon.flagstrap-sl {
  background-position: -160px -132px
}

.flagstrap-icon.flagstrap-sm {
  background-position: -176px -132px
}

.flagstrap-icon.flagstrap-sn {
  background-position: -192px -132px
}

.flagstrap-icon.flagstrap-so {
  background-position: -208px -132px
}

.flagstrap-icon.flagstrap-somaliland {
  background-position: -224px -132px
}

.flagstrap-icon.flagstrap-sr {
  background-position: -240px -132px
}

.flagstrap-icon.flagstrap-ss {
  background-position: 0 -143px
}

.flagstrap-icon.flagstrap-st {
  background-position: -16px -143px
}

.flagstrap-icon.flagstrap-sv {
  background-position: -32px -143px
}

.flagstrap-icon.flagstrap-sx {
  background-position: -48px -143px
}

.flagstrap-icon.flagstrap-sy {
  background-position: -64px -143px
}

.flagstrap-icon.flagstrap-sz {
  background-position: -80px -143px
}

.flagstrap-icon.flagstrap-tc {
  background-position: -96px -143px
}

.flagstrap-icon.flagstrap-td {
  background-position: -112px -143px
}

.flagstrap-icon.flagstrap-tf {
  background-position: -128px -143px
}

.flagstrap-icon.flagstrap-tg {
  background-position: -144px -143px
}

.flagstrap-icon.flagstrap-th {
  background-position: -160px -143px
}

.flagstrap-icon.flagstrap-tj {
  background-position: -176px -143px
}

.flagstrap-icon.flagstrap-tk {
  background-position: -192px -143px
}

.flagstrap-icon.flagstrap-tl {
  background-position: -208px -143px
}

.flagstrap-icon.flagstrap-tm {
  background-position: -224px -143px
}

.flagstrap-icon.flagstrap-tn {
  background-position: -240px -143px
}

.flagstrap-icon.flagstrap-to {
  background-position: 0 -154px
}

.flagstrap-icon.flagstrap-tr {
  background-position: -16px -154px
}

.flagstrap-icon.flagstrap-tt {
  background-position: -32px -154px
}

.flagstrap-icon.flagstrap-tv {
  background-position: -48px -154px
}

.flagstrap-icon.flagstrap-tw {
  background-position: -64px -154px
}

.flagstrap-icon.flagstrap-tz {
  background-position: -80px -154px
}

.flagstrap-icon.flagstrap-ua {
  background-position: -96px -154px
}

.flagstrap-icon.flagstrap-ug {
  background-position: -112px -154px
}

.flagstrap-icon.flagstrap-um {
  background-position: -128px -154px
}

.flagstrap-icon.flagstrap-us {
  background-position: -144px -154px
}

.flagstrap-icon.flagstrap-uy {
  background-position: -160px -154px
}

.flagstrap-icon.flagstrap-uz {
  background-position: -176px -154px
}

.flagstrap-icon.flagstrap-va {
  background-position: -192px -154px
}

.flagstrap-icon.flagstrap-vc {
  background-position: -208px -154px
}

.flagstrap-icon.flagstrap-ve {
  background-position: -224px -154px
}

.flagstrap-icon.flagstrap-vg {
  background-position: -240px -154px
}

.flagstrap-icon.flagstrap-vi {
  background-position: 0 -165px
}

.flagstrap-icon.flagstrap-vn {
  background-position: -16px -165px
}

.flagstrap-icon.flagstrap-vu {
  background-position: -32px -165px
}

.flagstrap-icon.flagstrap-wales {
  background-position: -48px -165px
}

.flagstrap-icon.flagstrap-wf {
  background-position: -64px -165px
}

.flagstrap-icon.flagstrap-ws {
  background-position: -80px -165px
}

.flagstrap-icon.flagstrap-ye {
  background-position: -96px -165px
}

.flagstrap-icon.flagstrap-yt {
  background-position: -112px -165px
}

.flagstrap-icon.flagstrap-za {
  background-position: -128px -165px
}

.flagstrap-icon.flagstrap-zanzibar {
  background-position: -144px -165px
}

.flagstrap-icon.flagstrap-zm {
  background-position: -160px -165px
}

.flagstrap-icon.flagstrap-zw {
  background-position: -176px -165px
}
