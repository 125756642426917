@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --theme-black: #010F3E;
  --deep-blue: #1D3A55;
  --happy-blue: #3E63E7;
  --faded-blue: #EEF6FF;
  --mid-blue: #7491B2;
  --white: #FFFFFF;
}

* {
  box-sizing: border-box;
  font-family: 'Archivo', sans-serif;
}


#phone_scams_alert {
  width: 100%;
  padding: 14px;
  position: relative;
  background: #FFF1F1;
}
.inner-phone-scams {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scame-alert-icon {
  max-width: 100%;
  margin: 0 18px 0 0;
}
.scams-alert {
  display: flex;
  align-items: center;
}
.scams-alert h2 {
  display: flex;
  color: #FF647C;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  font-style: normal;
  margin: 0 32px 0 0;
  padding: 0 32px 0 0;
  align-items: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}
.alert-links {
  display: flex;
  align-items: center;
}
.alert-links .read-more-details {
  width: 136px;
  height: 48px;
  color: #FF647C;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  line-height: 48px;
  font-style: normal;
  text-align: center;
  border-radius: 6px;
  background: #FFFFFF;
  text-decoration: none;
}
.icon-close-btn {
  top: 15px;
  right: 15px;
  display: flex;
  position: absolute;
}
@media screen and (max-width: 992px){
  .scame-alert-icon {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
  }
  .inner-phone-scams {
    justify-content: flex-start;
  }
  .scams-alert {
    align-items: flex-start;
    flex-direction: column;
  }
  .scams-alert h2 {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 7px 0;
    border: none;
    padding: 0;
  }
  .alert-links .read-more-details {
    width: 80px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .icon-close-btn {
    top: 10px;
    right: 10px;
    display: flex;
    position: absolute;
  }
}



/* New header CSS */


header {
  background: url(../../assets/images/BG.png) no-repeat center/cover;
  min-height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuBar .navbar {
  margin: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 99;
}
.menuBar nav .nav-item {
  margin: 0 40px 0 0;
}
.menuBar nav .nav-item .nav-link {
  background: transparent;
  color: var(--deep-blue);
  font-size: 14px;
  font-weight: 500;
}
.menuBar nav .nav-item .nav-link:hover, 
.menuBar nav .nav-item .nav-link.active {
  color: var(--theme-black);
}
.menuBar form {
  padding: 0 0 0 50px;
}
.mainHeading h1 {
  color: var(--theme-black);
  font-size: 62px;
  font-weight: 700;
  line-height: 60px;
  margin: 0 0 30px;
}
.mainHeading p {
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 64px;
}
.slider {
  padding: 100px 0 110px;
}
.sliderImg {
  width: 120%;
}
.btn.btn-primary {
  background: linear-gradient(90deg, #5200FF 1.24%, #CAB1FF 100%);
  border: none;
  border-radius: 60px;
  box-shadow: 0px 8px 24px 0px #010F3E26;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  height: 40px;
  width: 131px;
  transition: all ease-in-out 0.2s;
}
.btn.btn-primary:hover {
  background: var(--white);
  color: var(--happy-blue);
  box-shadow: inset 0 0 0 2px var(--happy-blue); 
}

.defaultBtn {  
  background: -webkit-linear-gradient(90deg, #5200FF 1.24%, #CAB1FF 100%);  
  background: -moz-linear-gradient(90deg, #5200FF 1.24%, #CAB1FF 100%);  
  background: -o-linear-gradient(90deg, #5200FF 1.24%, #CAB1FF 100%);  
  background: linear-gradient(90deg, #5200FF 1.24%, #CAB1FF 100%);  
  border: none;
  border-radius: 100px;
  box-shadow: 0px 8px 24px 0px #010F3E26;
  color: var(--white);
  cursor:pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  height: 60px;
  margin: 0 30px 0 0;
  width: 180px;
  transition: all ease-in-out 0.2s;
}  

.defaultBtn.white-bg {
  background: var(--white);
  color: var(--happy-blue);
  box-shadow: inset 0 0 0 2px var(--happy-blue);
}


.menuBar .ruby-menu>li.ruby-menu-mega>div {
  z-index: 99;
}


/* Responsive CSS */
@media (max-width: 991.98px) {
  /* menuBar css start here ==================================== */
  .menuBar .navbar .container {
    flex-wrap: initial;
  }
  .menuBar nav .nav-item .nav-link {
    font-size: 20px;
  }
  .center-side-navigation .ruby-menu {
    width: 100%;
  }
  .menuBar nav .nav-item {
    margin: 0;
  }
  /* menuBar css end here ==================================== */

  /* header css start here ==================================== */
  .slider {
    padding: 92px 0 69px;
  }
  .slider .container .row {
    flex-direction: column-reverse;
  }
  .sliderImg {
    width: 100%;
  }
  .mainHeading h1 {
    font-size: 40px;
    line-height: 43px;
    margin: 30px 0 24px;
  }
  .mainHeading p {
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 48px;
  }
  header {
    background-position: 25%;
    min-height: 700px;
  }
  .mainHeading {
    text-align: center;
  }
  .defaultBtn {
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    margin: 0 16px 0 0;
    width: 148px;
  }

  @media (max-width: 540px) {
    header {
      background: url('../../assets/images/BG-mobile.png') no-repeat center/cover;
    }
  }
  /* header css end here ==================================== */

  /* key Benefits css start here ==================================== */
  
  .keyBenefits {
    padding: 30px 0 52px;
  }
  .sectionHead {
    font-size: 40px;
  }

  /* key Benefits css end here ==================================== */

  /* Trusted by css start here ==================================== */

  .trustedBy {
    padding: 0 0 47px;
  }
  .trustedBy h5 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 36px;  
  }
  .trustedBy ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .trustedBy ul li {
    width: 30%;
    text-align: center;
    margin: 0 1.5% 35px
  }

  /* Trusted by css end here ==================================== */

  /* Footer css start here ==================================== */

  footer {
    padding: 15px 0;
  }
  .copyRight {
    flex-direction: column;
    margin: 0;
  }
  
  .socialIcons {
    display: flex;
  }
  .socialIcons ul li {
    margin-left: 14px;
  }

  /* Footer css end here ==================================== */
}




