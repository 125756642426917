
/* Footer CSS */
#footer {
  border-top: 1px solid #F0F0F0;
}
#footer ul{
  margin-bottom: 0rem;
}
.inner-footer {
  padding: 40px 0 20px;
}
.foot-links ul li h5 {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 12px;
  text-transform: uppercase;
  color: #828E99;
}
.foot-links ul li , .foot-links ul li a {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 20px;
  color: #000000;
  transition: all 0.3s;
}
.foot-links ul li a img {
  width: 100px;
}
.foot-links ul li {
  display: block;
  margin: 0;
}
.foot-links ul li a:hover {
  color: #0065FF;
}
.social-freditz-country {
  background: #F6F9FC;
  padding: 15px 0px;
}
.social-copyrights {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyright {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #828E99;
}
.social-links {
  margin: 0 0 0 -80px;
}
.social-links a {
  display: inline-block;
  margin: 0 5px;
}
.select-language .dropdown-toggle {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  background: none;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}
.select-language .dropdown-toggle img {
  margin: 0 5px;
}
.select-language .dropdown-toggle .caret {
  color: transparent !important;
  background: none !important;
  border: 0 !important;
  width: 10px !important;
  height: 6px !important;
  margin: -9px 0 0 7px !important;
  vertical-align: top !important;
}
.select-language .dropdown-toggle .caret img {
  margin: 0;
}
.select-language .dropdown .dropdown-menu {
  top: auto;
  bottom: 25px !important;
  min-width: 100%;
  min-height: 0;
  overflow-y: auto;
  max-height: 117px;
  margin: 0;
  padding: 0;
}
.select-language .dropdown .dropdown-menu li a {
  padding: 3px 6px 5px;
}
.dropdown-toggle::after {
  display: none !important;
}
.open>.dropdown-menu {
  display: block;
}

.download-footer, .select-language{
  display: none;
}

.col-margin{
  margin-right: 3.3%;
}


footer {
  background-color: #E5F1FF;
  padding: 15px;
}
.copyRight {
  display: flex;
  justify-content: space-between;
  margin: 0 5rem 0 0;
}
.copyRight p {
  color: var(--deep-blue);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}
.socialIcons {
  display: flex;
}
.socialIcons ul {
  display: flex;
  margin-left: auto;
}
.socialIcons ul li {
  margin-left: 16px;
}

@media (max-width: 991px) {
  footer {
    padding: 15px 0;
  }
  .copyRight {
    margin: 0;
  }
  .copyRight p:first-child {
    margin-bottom: 5px;
  }
  .socialIcons ul {
    margin: 0;
    padding: 0;
  }
  .socialIcons ul li {
    margin: 0;
  }
  .socialIcons ul li:nth-child(2) {
    margin: 0 10px;
  }
}