/* Login CSS =================================== */
.kreditz-login .inner-login {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	padding: 20px 10px;
}

.kreditz-login .login-form {
	max-width: 375px;
	width: 100%;
	box-sizing: border-box;
	padding: 15px 20px;
}

.kreditz-login .select-language {
	margin: 0 0 40px;
}

.kreditz-login .select-language .dropdown {
	position: relative;
}

.kreditz-login .select-language .dropdown .dropdown-toggle {
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	color: #000000;
}

.kreditz-login .select-language .dropdown .dropdown-menu {
	min-width: 120px;
	bottom: auto;
	top: 100%;
}

.kreditz-login .select-language .dropdown .dropdown-toggle img {
	margin: 0 7px 0 0;
	width: 30px;
}

.kreditz-login .select-language .dropdown-toggle .caret {
	color: transparent;
	background: none;
	border: 0;
	width: 12px;
	height: 6px;
	margin: -15px 0 0 7px;
}

.kreditz-login form .form-group {
	position: relative;
	margin: 0 0 28px;
}

.kreditz-login form .form-group label {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #999999;
	position: absolute;
	left: 15px;
	top: -7.5px;
	background: #fff;
	padding: 0 5px;
	z-index: 9;
}

.kreditz-login form .form-group input {
	border: 2px solid #ECECEC;
	box-sizing: border-box;
	border-radius: 4px;
	height: 58px;
	padding: 0 18px;
}

.kreditz-login #kreditz_pass {
	padding: 0 18px 0 18px;
}

.kreditz-login .show-pass {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
	height: 58px;
	border: none;
	width: 58px;
	background: none;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #999999;
	text-align: center;
	line-height: 58px;
}

.kreditz-login .forgot-password {
	text-align: right;
	margin: -12px 0 0 0;
}

.kreditz-login .forgot-password a {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
	transition: all 0.3s;
}

.kreditz-login .forgot-password a:hover {
	color: #0065FF;
}

.kreditz-login .login-submit {
	background: #0065FF;
	border-radius: 6px;
	height: 58px;
	display: block;
	width: 100%;
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	margin: -10px 0 0 0;
}

.kreditz-login .kreditz-signup {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	margin: 0 0 50px;
}

.kreditz-login .kreditz-signup a {
	color: #0065FF;
}

.kreditz-login .copy-right {
	font-family: Arial;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #828E99;
	margin: 26px 0 0 0;
}

/* Index/Home CSS =================================== */
.get-in-touch-banner {
	background: #fff url(../../assets/images/graph_line.png) no-repeat scroll center center / 100%;
	height: 100vh;
	box-sizing: border-box;
	padding: 80px 0 50px;
	display: flex;
	align-items: center;
}

.brain-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.left-side-get-touch h1 {
	font-family: "Sharp Grotesk DB Cyr Medium 22";
	font-style: normal;
	font-weight: 500;
	font-size: 50px;
	line-height: 66px;
	color: #000;
}

.left-side-get-touch h6 {
	margin: 10px 0 20px;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 40px;
	color: #425466;
}

.get-in-touch-banner .get-start,
.white-btn {
	margin: 0 20px 0 0;
}

.get-in-touch-banner .login {
	color: #0065FF;
}

.get-in-touch-banner .login img {
	margin: 0 0 0 10px;
}

.color-full-base {
	margin: 0 0 0;
}

#funnel-set {
	padding: 80px 0 0 0;
}

.funnel-set-image {
	text-align: center;
	margin: 80px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.work-accuracy {
	margin: 0 0 100px;
}

.accuracy-management {
	margin: 30px 0 0;
}

.accuracy-management ul li {
	background: #FFFFFF;
	border-radius: 4px;
	box-shadow: 0px 10px 32px rgba(50, 50, 71, 0.08), 0px 16px 16px rgba(50, 50, 71, 0.08);
	margin: 0 0 24px;
	padding: 14px 20px;
	display: flex;
	align-items: center;
	position: relative;
}

.accuracy-management ul li span {
	display: inline-block;
	background: #F0F0F0;
	border-radius: 4px 4px 0px 0px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: #425466;
	padding: 7px 9px;
	position: absolute;
	left: 14px;
	top: -33px;
}

.blue-kriditz {
	background: #0065FF !important;
	color: #fff !important;
}

.span-sp {
	display: none !important;
}

.accuracy-img-set {
	margin: 0 15px 0 0;
	width: 64px;
}

.accuracy-description p {
	font-style: normal;
	font-weight: normal;
	font-size: 18.6591px;
	line-height: 22px;
	color: #425466;
	margin: 8px 0;
}

.accuracy-description strong {
	font-family: "Sharp Grotesk DB Cyr Medium 22";
	font-style: normal;
	font-weight: 500;
	font-size: 18.6591px;
	line-height: 24px;
	color: #000000;
}

.succes-blue {
	color: #0065FF !important;
}

.strong-bad {
	color: #FF647C !important;
}

.strong-good {
	color: #55CC41 !important;
}

.ecosystem-graph {
	margin: 80px 0 !important;
	display: flex;
	align-items: stretch;
}

.ecosystem-graph .col-md-6 {
	padding: 0;
}

.ecosystem-graph .echo-graph img {
	width: 100%;
}

.ecosystem-graph-description ul {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 50px 0 0 20px;
}

.ecosystem-graph-description ul li {
	display: flex;
	padding: 40px 0;
	align-items: center;
}

.ecosystem-graph-description ul li img {
	width: 75px;
	margin: 0 20px 0 0;
}

.ecosystem-graph-description ul li p {
	width: calc(100% - 95px);
	font-family: "Sharp Grotesk DB Cyr Medium 22";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	color: #425466;
}

/* Plan CSS =================================== */
.banner-text {
	padding: 180px 0 100px 0;
}

.tab-list-view ul {
	display: flex;
	align-items: center;
	margin: 0px 0 80px;
}

.tab-list-view ul li+li {
	margin: 0 0 0 30px;
}

.tab-list-view ul li a {
	font-family: Arial;
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 30px;
	color: #425466;
	border-bottom: 3px solid transparent;
}

.tab-list-view ul li a:hover,
.tab-active-border {
	color: #0065FF !important;
	border-bottom: 3px solid #0065FF !important;
}

.tab-list-details {
	padding: 0 80px;
}

.custom-table {
	width: 100%;
}

.custom-table tr th {
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding: 18px 10px;
}

.custom-table tr th:nth-child(1) {
	width: 30%;
}

.custom-table tr td {
	font-style: normal;
	font-size: 20px;
	line-height: 24px;
	color: #828E99;
	padding: 18px 10px;
	text-align: center;
}

.custom-table tr td:nth-child(1) {
	text-align: left;
}

.custom-table tr td span {
	color: #425466;
}

.custom-table tr td .get-start {
	margin: 0;
}

.availability-link {
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: #828E99;
	margin: 80px 0 100px;
}

.availability-link a {
	color: #0065FF;
}

.availability-link ul {
	display: flex;
	align-items: center;
	margin: 0 -15px 0px;
	flex-wrap: wrap;
	justify-content: space-between;
}

.availability-link ul li {
	margin: 0 15px;
}

.faq-list {
	background: #F6F9FC;
	padding: 100px 0;
}

.accordion-container {
	margin: 30px 0 0 0;
}

.set {
	position: relative;
}

.set>a {
	display: block;
	transition: all 0.2s linear;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
	padding: 20px 40px 20px 20px;
	position: relative;
	border: 1px solid #fff;
	border-top: 1px solid #E1E5E9;
	border-bottom: none;
}

.set>a .fa-plus:before {
	position: absolute;
	content: '';
	right: 20px;
	top: 50%;
	margin: -10px 0 0 0;
	width: 20px;
	height: 20px;
	background: url(../../assets/images/plus.png) no-repeat scroll 0 0 / 20px;
}

.set>a .fa-minus:before {
	position: absolute;
	content: '';
	right: 20px;
	top: 50%;
	margin: -1px 0 0 0;
	width: 20px;
	height: 2px;
	background: url(../../assets/images/minus.png) no-repeat scroll 0 0 / 20px;
}

.set>a.active-accordian {
	border: 1px solid #E1E5E9;
	border-bottom: none;
}

.content-accordian {
	display: none;
	border: 1px solid #E1E5E9;
	border-top: none;
}

.content-accordian p {
	font-style: normal;
	font-size: 18px;
	line-height: 21px;
	color: #828E99;
	font-weight: normal;
	padding: 0px 20px 20px 20px;
}

/* Income Verfication CSS  =================================== */
.verification-tab-list-view {
	padding: 110px 0 0px 0;
}

.verification-tab-list-view ul {
	margin: 0 0 30px;
}

.verification-tab-list-details {
	padding: 0;
}

.custom-insights {
	margin: 50px 0 0 0;
}

.custom-insights ul {
	display: block;
}

.custom-insights ul li {
	display: flex;
	align-items: center;
	margin: 0 0 36px;
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: #596980;
}

.custom-insights ul li+li {
	margin: 0 0 30px;
}

.custom-insights ul li img {
	margin: -1px 15px 0 0;
}

.custom-insights .get-start {
	margin: 0;
}

.logo-description figure {
	width: 170px;
}

.border-blue {
	border: 1.05176px solid #0065FF;
}

.logo-description div img {
	margin: -20px 0 0 6px;
}

@media screen and (max-width: 991px) {
	.accuracy-management ul li {
		margin: 0 0 46px !important;
	}

	.span-sp {
		display: inline-block !important;
	}
}

.login-mobile {
	margin-top: 5%;
	font-size: 30px;
}


/* key benefits CSS */

.keyBenefits {
	padding: 110px 0 60px;
}

.sectionHead {
	color: var(--theme-black);
	font-size: 48px;
	font-weight: 700;
	line-height: 60px;
	margin: 0 0 37px;
}

.keyBenefits .card {
	background-color: var(--faded-blue);
	border: none;
	border-radius: 16px;
	height: 100%;
	padding: 18px;
}

.keyBenefits .cardHead {
	align-items: center;
	display: flex;
	margin: 0 0 10px;
}

.keyBenefits .cardHead img {
	margin-right: 22px;
}

.keyBenefits .cardHead h3 {
	color: var(--theme-black);
	font-size: 54px;
	font-weight: 700;
	line-height: 58px;
	margin: 0;
}

.keyBenefits .card h4 {
	color: var(--theme-black);
	font-size: 30px;
	line-height: 32px;
	font-weight: 500;
	margin: 0 0 16px;
}

.keyBenefits .card p {
	color: var(--mid-blue);
	font-size: 20px;
	line-height: 22px;
	margin: 0 0 16px;
}

.kbCard {
	width: 294px;
	margin: 0 15px !important;
}

.main-body {
	min-height: calc(100vh - 60px);
}

.keyBenefits .keyCarousel {
	max-width: 1140px;
	/* margin: 0 auto; */
}

@media (min-width: 1400px) {
	.keyBenefits .keyCarousel {
		max-width: 1320px;
	}
}

/* trusted by CSS */

.trustedBy {
	padding: 0 0 100px;
	display: block;
}

.trustedBy h5 {
	color: var(--theme-black);
	font-size: 24px;
	font-weight: 400;
	line-height: 32px;
	margin: 0 0 22px;
}

.trustedBy ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
}