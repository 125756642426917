div.cookie-container {
    color: #5b6a83;
    padding: 100px 1% 5px 1%;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}
thead {
    color: #5b6a83;
}
th {
    width: 25%;
    text-align: left;
    border-bottom: 1px solid black;
}
.cookie-cookie-link-tag {
    color: blue;
    font-weight: 600;
}
tr.cookie-tr{
    border-bottom: 1px solid black;
}
td.cookie-td, th.cookie-th{
    padding: 1%;
}
th.cookie-th{
  font-weight: bold;
  color: black;
}
td.cookie-td{
  vertical-align: top;
}

div.banner-padding-cookie{
    padding-top: 100px;
}
