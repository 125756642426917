.products-tabs {
	margin: 80px 0 150px !important;
}
.products-tabs ul {
	flex-direction: column;
	align-items: flex-start;
	margin: 0;
	border: none;
}
.products-tabs ul li {
	box-sizing: border-box;
	display: flex;
	width: 100%;
	border-left: 2px solid #828E99;
}
.products-tabs ul li+li {
	margin: 32px 0 0 0;
}
.products-tabs ul li a {
	font-family: Arial;
	padding: 6px 18px 0 18px;
	display: block;
	width: 100%;
	background: none !important;
	border: none !important;
	font-weight: normal;
	margin: 0;
	display: flex;
	align-items: flex-start;
	cursor: pointer;
}
.products-tabs ul li a img {
	width: 25px;
	margin: 0 12px 0 0;
}
.products-tabs ul li a .product-tab-details {
	width: calc(100% - 37px);
	transition: all 0.3s;
}
.products-tabs ul li a:hover {
	border: none !important;
	color: #828E99 !important;
}
.products-tabs ul li a .product-tab-details h4 {
	font-size: 18px;
	font-family: Arial;
	line-height: 29px;
	color: #828E99;
}
.products-tabs ul li.active a .product-tab-details h4 {
	font-weight: bold;
}
.products-tabs ul li a .product-tab-details p {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #425466;
	margin: 8px 0 6px;
	transition: all 0.3s;
	display: none;
}
.products-tabs ul li a .product-tab-details span {
	font-size: 14px;
	line-height: 29px;
	color: #0065FF;
	cursor: pointer;
	font-family: Arial;
	transition: all 0.3s;
	display: none;
}
.products-tabs ul li a .product-tab-details span img {
	margin: 0 0 0 2px;
	width: 20px;
}
.products-tabs .tab-pane img {
	width: 100%;
}
.products-tabs ul li.active {
	border-left: 2px solid #0065FF;
}
.products-tabs ul li.active a {
	padding: 18px 18px 0 18px !important;	
}
.products-tabs ul li.active a .product-tab-details p, .products-tabs ul li.active a .product-tab-details span {
	display: block;
}
.products-tabs ul.use-cases-lists li a {
	padding: 6px 18px 0 0px;
}
.products-tabs ul.use-cases-lists li.active a {
    padding: 18px 18px 0 0px !important;
}
.products-tabs ul.use-cases-lists li a strong {
	width: 36px !important;
	margin: 0 14px 0 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 29px;
}
.products-tabs ul.use-cases-lists li a strong img {
	width: auto !important;
	margin: 0 !important;
}
.products-tabs ul.use-cases-lists  li a .product-tab-details {
	width: calc(100% - 57px);
}
div.banner-padding-use-cases{
  padding-top: 90px;
}








































