a{
  text-decoration: none !important;
}
.font22{
  font-size: 22px !important;
}
.mt20{
  margin-top: 20px !important;
}
.width130{
  width: 130px !important;
}
.pr0{
  padding-right: 0px!important;
}
.pr0-font {
  font-size: 18px!important;
  color: #828E99!important;
}
.link-color{
  color: #828e99 !important;
  /* font-weight: normal !important; */
}
.center-side-navigation .open-link{
  cursor: pointer;
}