@media screen and (max-width: 1100px) {
	.ruby-menu>li+li {
		margin: 0 0 0 20px;
	}

	.careeer {
		background: #0052CC;
	}

	.careeer::before,
	.careeer::after {
		display: none;
	}

	.banner-padding-use-cases,
	.banner-padding-contact,
	.banner-padding,
	.banner-padding-products {
		padding-top: 100px;
	}
}

@media screen and (min-width: 1100px) {
	.ruby-menu li .open-link span {
		transform: rotate(0deg);
	}

	.ruby-menu>li .open-link:hover span {
		transform: rotate(-180deg);
	}

	div.cookie-container {
		padding-left: 1.5%;
		padding-right: 1.5%;
	}
}

@media screen and (max-width: 1024px) {
	.staff-members ul li {
		width: 19%;
	}
}

.menu-bar-icon,
.img_sp,
.get-in-touch-sp,
.br_sp {
	display: none;
}

.menu-bar-icon {
	cursor: pointer;
}

.img_pc {
	display: block;
}

@media screen and (max-width: 991px) {
	#header {
		box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08);
	}

	.inner-header {
		padding: 15px 15px 16px;
	}

	.left-side .logo a img {
		width: 118px;
	}

	.right-side-brain {
		padding: 0;
		width: 100%;
	}

	.right-side-brain img {
		width: 100%;
	}

	.right-side {
		display: none;
	}

	.menu-bar-icon {
		display: flex;
		flex: 0 0 6%;
	}

	.navbar-brand {
		flex: 0 0 114px;
		display: flex;
		padding: 0;
		margin: 0 0 0 16px;
	}

	.center-side-navigation {
		position: fixed;
		left: 0;
		top: 57px;
		width: 100%;
		height: calc(100% - 60px);
		overflow-y: auto;
		background: #e5f1ff;
		display: none;
		padding: 15px;
	}

	.center-side-navigation-sp {
		top: 143px;
		height: calc(100vh - 143px);
	}

	.ruby-menu {
		flex-direction: column;
	}

	.ruby-menu>li+li {
		margin: 0;
	}

	.ruby-menu>li .open-link {
		padding: 10px 15px;
		border-bottom: 1px solid #eaeaea;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.ruby-menu>li.ruby-menu-mega>div {
		position: static;
		visibility: visible !important;
		opacity: 1 !important;
		height: auto;
		border-bottom: 1px solid #eaeaea;
		display: none;
	}

	.ruby-menu>li.ruby-menu-mega>.full-dropdown .inner-full-dropdown .menu-drop-down {
		background: #e5f1ff;
		padding: 15px;
	}

	.h3-basic01 {
		font-size: 18px;
		line-height: 30px;
		margin: 6px 0 0px;
	}

	.menu-para {
		font-size: 15px;
		line-height: 21px;
	}

	.inner-footer {
		padding: 30px 0 0;
		border-top: 1px solid #C2C7CC;
		margin: 0px 0 -20px 0;
	}

	.inner-footer .row {
		display: flex;
		flex-wrap: wrap;
	}

	.inner-footer .row .col-md-2 {
		width: 50%;
		margin: 0 0 25px;
	}

	.social-copyrights {
		flex-wrap: wrap;
	}

	.social-copyrights li {
		width: 50%;
	}

	.social-links {
		text-align: right;
	}

	.select-language {
		width: 100% !important;
		display: flex;
		justify-content: flex-end;
		padding: 20px 15px 10px 0;
		display: none;
	}

	.br_sp {
		display: block;
	}

	/* About =============*/

	.customers-list {
		margin: 0 0 30px;
	}

	.customers-list ul {
		padding: 0;
	}

	.customers-list ul li {
		width: 33.33%;
	}

	.careeer .row {
		flex-direction: column;
	}

	.careeer .row .col-md-5 {
		text-align: center;
	}

	.products-tabs {
		margin: 40px 0 20px !important;
	}

	.testimonials-slides {
		flex-direction: column;
	}

	.testimonials-slides figure.col-md-6 {
		margin-bottom: 20px;
	}

	.testimonials-slides .col-md-6 {
		width: 100%;
	}

	#footer-logo {
		min-width: 80%;
	}

	.banner-padding-products,
	.banner-padding-use-cases,
	.banner-padding-contact,
	.banner-padding-booking {
		padding-top: 50px;
	}

	div.cookie-container {
		min-height: 75vh;
	}

	div.booking-container {
		min-height: 65vh;
	}
}

@media screen and (max-width: 768px) {
	.h4-basic01 {
		font-size: 16px;
		line-height: 30px;
		color: #828E99;
		margin: 6px 0 0;
	}

	.pt-45 {
		padding-top: 0;
	}

	.get-in-touch-banner {
		background: none;
		height: auto;
		padding: 80px 0 30px;
	}

	.brain-header {
		flex-direction: column;
	}

	.left-side-get-touch h1 {
		font-size: 36px;
		line-height: 46px;
	}

	.img_sp {
		display: block;
	}

	.sp-padding-0 {
		padding: 0;
	}

	.sp-padding-0 h2,
	.sp-padding-0 h6 {
		padding: 0 15px;
	}

	.get-in-touch-sp {
		display: block;
		padding: 0 15px;
		width: 100%;
	}

	.img_pc {
		display: none;
	}

	.right-side-buttons {
		display: none;
	}

	.get-in-touch-sp {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.funnel-set-image {
		margin: 40px 0 60px -15px;
	}

	.accuracy-management ul li {
		margin: 0 0 6px;
	}

	.mt-sp22 {
		margin-top: 25px;
	}

	.work-accuracy {
		margin: 0 0 40px;
	}

	.testimonials-logs {
		margin: 60px 0 80px;
	}

	.testimonials-slides {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
	}

	.clients-image {
		/* width: 190px; */
		margin: 0 0 30px;
	}

	.clients-review {
		width: 100%;
	}

	.logo-description div,
	.logo-description p {
		font-size: 16px;
		line-height: 24px;
	}

	.testimonials-logs .slick-arrow {
		bottom: 0;
	}

	.logo-description figure img {
		width: 130px;
	}

	.testimonials-logs .slick-next {
		right: 20px;
	}

	.testimonials-logs .slick-prev {
		right: 87px;
	}

	.h2-basic01,
	.h1-basic01 {
		font-size: 30px;
		line-height: 36px;
	}

	.h6-basic01 {
		font-size: 16px;
		line-height: 24px;
	}

	.ecosystem-graph {
		margin: 20px 0 0 0;
		flex-direction: column;
	}

	.echo-graph {
		margin: 0 -15px;
	}

	.ecosystem-graph-description ul li img {
		width: 20px;
		margin: 0 12px 0 0;
	}

	.ecosystem-graph-description ul li p {
		width: calc(100% - 32px);
		font-size: 16px;
		line-height: 24px;
	}

	.ecosystem-graph-description ul {
		margin: 20px 0 0 0;
	}

	.ecosystem-graph-description ul li {
		padding: 0 0 20px;
	}

	.verification-tab-list-view ul {
		flex-wrap: wrap;
		margin: 0;
	}

	.tab-list-view ul {
		margin: 0 -15px;
		flex-wrap: wrap;
	}

	.tab-list-view ul li,
	.tab-list-view ul li+li {
		margin: 0 15px 15px;
	}

	.h3-basic02 {
		font-size: 20px;
		line-height: 30px;
		color: #425466;
		margin: 14px 0 10px 0;
	}

	.h6-basic03 {
		font-size: 16px;
		line-height: 26px;
	}

	.custom-insights {
		margin: 10px -15px 0;
	}

	#corporate-revenue .width-1200,
	#income-verification .width-1200,
	#spending-behavior .width-1200,
	#asset-accumulation .width-1200,
	#debt-involvement .width-1200,
	#credit-decisioning01 .width-1200,
	#custom-products .width-1200,
	#corporate-spending .width-1200,
	#corporate-assets .width-1200,
	#corporate-debt .width-1200,
	#credit-decisioning02 .width-1200,
	#custom-products01 .width-1200 {
		padding: 0;
	}

	.banner-text {
		padding: 80px 0 30px 0;
	}

	.tab-list-details {
		padding: 0;
	}

	.custom-table tbody tr th {
		min-width: 250px;
	}

	.availability-link {
		margin: 20px 0;
	}

	.h5-basic01 {
		margin: 0 0 14px;
	}

	.availability-link ul {
		justify-content: center;
		align-items: center;
	}

	.faq-list {
		padding: 0px 0;
	}

	.accordion-container {
		margin: 15px 0 0 0;
	}

	.set>a {
		font-size: 22px;
		line-height: 29px;
		color: #000000;
		padding: 10px 40px 10px 20px;
	}

	/* About ================================*/
	.mt-40 {
		margin: 15px 0 40px;
	}

	.group-values ul {
		flex-direction: column;
	}

	.custom-insights.group-values .col-md-6 {
		width: 100%;
	}

	.group-values ul li {
		margin: 0 0 20px;
		align-items: center;
		width: 100%;
	}

	.group-values {
		margin: 35px 0 0 !important;
		flex-direction: column;
	}

	.start-kreditz {
		padding: 40px 0 0 0;
	}

	.build-team {
		padding: 30px 0 20px;
	}

	.staff-members .width-1200 {
		position: relative;
		padding: 40px 0 0;
	}

	.staff-members .width-1200 .staff-heading {
		padding: 0 15px;
		font-size: 22px;
		line-height: 42px;
		left: 15px;
		top: -23px;
	}

	.customers-list ul li {
		width: 50%;
		margin: 0 0 30px;
	}

	.products-tabs {
		margin: -20px 0 20px !important;
	}

	.banner-padding-about {
		padding-top: 80px;
	}

	.banner-padding-plans {
		padding-top: 150px;
	}

	.brain-header.banner-padding {
		padding-top: 90px;
	}

	.plans-main {
		min-height: 61vh;
	}

	div.cookie-container {
		padding-left: 2%;
		padding-right: 2%;
	}

	.white-btn {
		margin: 0;
	}

	ul.prods-tabs li {
		max-width: 50%;
		min-width: 40%;
		margin: 0;
		margin-left: 30px;
	}
}

@media screen and (max-width: 640px) {
	.customers-list ul li {
		width: 100%;
	}

	.staff-members ul li {
		width: 24%;
	}

	div.cookie-container {
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
}

@media screen and (max-width: 480px) {
	.staff-members ul li {
		width: 32.33%;
	}

	div.cookie-container {
		padding-left: 3%;
		padding-right: 3%;
	}

	ul.prods-tabs li {
		margin: 0;
		margin-left: 30px;
		width: 42%;
		margin-right: 20px;
	}
}

@media screen and (max-width: 360px) {
	.staff-members ul li {
		width: 48%;
		margin: 0 1% 10px;
	}

	div.cookie-container {
		padding-left: 4%;
		padding-right: 4%;
	}

	ul.prods-tabs li {
		margin: 0;
		margin-left: 30px;
		width: 50%;
	}

}