/* ABOUT  =================================== */
.mt-40 {
  margin-top: 40px;
}
.group-values {
  margin: 70px 0 0 !important;
}
.group-values ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.group-values ul li {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 21px;
  color: #425466;
  align-items: flex-start;
  margin: 0 0 60px;
}
.group-values ul li {
  margin: 0 0 60px;
}  
.group-values ul li h2 {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  font-weight: 600;
  font-size: 42px;
  line-height: 40px;
  color: #000000;
  margin: 0 0 20px;
}
.group-values h6 {
  font-size: 18px;
  line-height: 29px;
  color: #425466;
}
.start-kreditz {
  padding: 135px 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.build-team {
  background: linear-gradient(180deg, #F6F9FC 0%, #FFFFFF 100%);
  padding: 90px 0 0px;
}
#team-members, #board-members , #investors{
  padding: 70px 0 0 0;
}
.pb-0 {
  padding-bottom: 0;
}
.staff-members {
  margin: 40px 0 0;
  border-top: 2px solid #0065FF;
}
.staff-members .width-1200 {
  position: relative;
  padding: 80px 0 0;
}
.staff-members .width-1200 .staff-heading {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  position: absolute;
  padding: 0 30px;
  font-weight: 600;
  font-size: 42px;
  line-height: 65px;
  color: #000000;
  left: 0;
  top: -33px;
  background: #fff;
}
.staff-members ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
}
.staff-members ul li {
  font-family: "Sharp Grotesk DB Cyr Medium 22";
  text-align: center;
  width: 15.3%;
  margin: 0 0.5% 30px;
  font-size: 13px;
  /* font-size: 14px; */
  line-height: 20px;
  color: #000000;
  align-items: stretch;
}
.staff-members ul li img {
  height: 185px;
  object-fit: cover;
  object-position: top;
  width: 100%;
}
.staff-members ul li p {
  font-weight: bold;
  margin: 10px 0 10px;
}
.staff-members ul li span {
  font-size: 11px;
  /* font-size: 12px; */
  line-height: 20px;
  color: #828E99;
  display: block;
}
.customers-list {
  border-bottom: 1px solid #f0f0f0;
  margin: 0 0 80px;
}
.customers-list ul {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 0 40px;
}
.customers-list ul li {
  width: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 60px;
}
.single-slide-testimonials .testimonials-slides {
  margin: 60px 0 0;
}
.single-slide-testimonials .testimonials-slides-right .clients-image {
  margin: 0 0 0 50px;
}
.careeer {
  position: relative;
  margin: 100px 0 0 0;
  background: #0052CC url('../../assets/images/dots.svg') no-repeat scroll left 45px bottom 100px;
  padding: 30px 0 0 0;
}
.careeer::before {
  position: absolute;
  content: '';
  left: 0;
  top: 100px;
  width: 176px;
  height: 89px;
  background: url('../../assets/images/wave_left.svg') no-repeat scroll 0 0 / 176px 89px;
}
.careeer::after {
  position: absolute;
  content: '';
  right: 0;
  bottom: 120px;
  width: 89px;
  height: 89px;
  background: url('../../assets/images/wave_left.svg') no-repeat scroll 0 0 / cover;
}
.careeer .row {
  display: flex;
  align-items: center;
}
.careeer h2 {
  line-height: 56px;
  font-size: 42px;
  color: #fff;
  margin: 0 0 16px;
}
.careeer h6 {
  color: #fff;
  margin: 0 0 22px;
}
.careeer .know-more {
  font-size: 16px;
  line-height: 27px;
  display: inline-block;
  color: #FFFFFF;
  border-radius: 6pX;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 6px 20px;
}
#investors .staff-members .investor-list li {
  width: 23%;
  margin: 0 1% 20px;
}
#investors .staff-members ul li img {
  height: auto;
  width: auto;
}
.investor-list li figure {
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 10px;
  margin: 0 0 10px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.investor-list li p {
  margin: 0;
}

.custom-insights.group-values {
  display: flex;
  align-content: stretch;
}
.custom-insights.group-values .col-md-6 {
  width: 50%;
}
.custom-insights.group-values .col-md-6:nth-child(1) {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;
}
.custom-insights.group-values .col-md-6:nth-child(1) ul {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.custom-insights.group-values .col-md-6:nth-child(1) ul li {
  width: 50%;  
  justify-content: flex-start;
  align-items: flex-start;
}



@media screen and (max-width: 991px){
  #team-members, #board-members , #investors{
    padding: 56px 0 0 0;
  }
  .staff-members ul li {
    width: 22.5%;
  }
  .staff-members ul li img {
    height: 160px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}
@media screen and (max-width: 768px) {
  .staff-members .width-1200 .staff-heading {
    padding: 0 15px;
    font-size: 22px;
    line-height: 42px;
    left: 15px;
    top: -23px;
  }
  .custom-insights.group-values .col-md-6 {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .staff-members ul li {
    width: 31%;
  }
  .staff-members ul li img {
    height: 160px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  #investors .staff-members .investor-list li {
    width: 45%;
  }
}
@media screen and (max-width: 480px) {
  .staff-members ul li {
    width: 45%;
  }
}







