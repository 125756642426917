.term-and-condition {
  padding: 130px 0 25px;
}

.inner-terms-container {
  padding: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
  color: #5b6a83;
}

.inner-terms-container p {
  font-size: 16px;
}

.inner-terms-container .col-md-12 {
  margin-top: 20px;
}

.tc-head {
  text-align: center;
  margin-bottom: 20px;
}

.text-table {
  display: flex;
  justify-content: flex-start;
}

.text-table p {
  flex: 1;
}

.general-text p {
  padding-left: 20px;
}

.responsibility-text li {
  list-style-type: disc;
  margin-left: 20px;
}

.terms-li {
  list-style-type: disc;
  font-size: 16px;
}